import jQuery from 'jquery';

jQuery(function($) {
  var newsCatNav=$("li[data-newscat]");
  var newsLists=$("ul[data-newscat]");
  newsCatNav.click(function(e) {
    e.preventDefault();
    newsCatNav.removeClass("active");
    $(this).addClass("active");
    var catId=$(this).attr("data-newscat");
    newsLists.addClass("hidden").filter("[data-newscat='"+catId+"']").removeClass("hidden");
  });
});



